import HomePage from '../components/pages/homepage/Page';
import * as Sentry from '@sentry/nextjs';
import type { PrismicDocument } from '@prismicio/types';

import { GetStaticProps, GetStaticPropsContext } from 'next';
import { ISR_CONTROL } from '../constants/revalidate';

import { getTopProducts } from '../utils/getTopProducts';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import { createClient } from '../prismicio';
import {
  categoryMappingsCache,
  newProductsCache as productsCache,
  listOfLinksCache,
} from '../utils/cache';
import { SlicesAdapter } from '../utils/prismic/SlicesAdapter';
import { isPageDarkTheme } from '../utils/page';
import {
  blogContentRelationship,
  ttdContentRelationship,
  clpContentRelationship,
  curatedCarouselTabContentRelationship,
} from '@virginexperiencedays/cms/constants';

import type { ProductMapType } from '@virginexperiencedays/products';
import type { BasePageProps as PageProps } from '../components/pages/types';
import { PAGE_TYPES } from '../components/tracking/gtm/consts';

import { fetchVedNavigationItems } from '../utils/precache/navigation-items';

const navigationServiceUrl = process?.env?.NEXT_PUBLIC_NAVIGATION_SERVICE_BASE_URL;

// This page will only be accessed, when...
// - FF_AB_TESTS is disabled, or
// - DY AB choose response is empty
const PRISMIC_PAGE_TYPE = 'home_page_repeatable';
const PRISMIC_PAGE_UID = 'homepage-a';

export const getStaticProps: GetStaticProps<PageProps> = async ({
  previewData,
}: GetStaticPropsContext) => {
  const slug = '/';
  const prismicClient = createClient({ previewData });
  const initialNavItems = await fetchVedNavigationItems(navigationServiceUrl);

  // fetch prismic page and top products data
  const topProductsPromise = getTopProducts();
  const prismicPagePromise = prismicClient.getByUID(PRISMIC_PAGE_TYPE, PRISMIC_PAGE_UID, {
    fetchLinks: [
      ...blogContentRelationship,
      ...ttdContentRelationship,
      ...clpContentRelationship,
      ...curatedCarouselTabContentRelationship,
    ],
  });

  // utilise allSettled as we don't want to error if topProducts fails
  const [topProductsResult, prismicPage] = await Promise.allSettled([
    topProductsPromise,
    prismicPagePromise,
  ]);

  let topProducts: ProductMapType = {};
  if (topProductsResult.status === 'fulfilled') {
    topProducts = topProductsResult.value as ProductMapType;
  } else {
    Sentry.captureException(new Error(topProductsResult.reason));
  }

  // if there is no Prismic page data we should fallback
  // TODO ultimately we want to apply a better fallback than a 404 to the home page
  if (prismicPage.status === 'rejected') {
    return { notFound: true };
  }

  const homePage = prismicPage?.value as PrismicDocument;
  if (!homePage) {
    return { notFound: true };
  }

  const slicesAdapter = new SlicesAdapter(
    homePage,
    productsCache,
    categoryMappingsCache,
    PHASE_PRODUCTION_BUILD
  );

  const prismicSlices = await slicesAdapter.integrate(homePage?.data?.slices ?? []);

  const listOfLinks = await listOfLinksCache.get();

  return {
    props: {
      trackingPageType: PAGE_TYPES.Home,
      isDarkTheme: isPageDarkTheme({ theme: homePage?.data?.page_theme }),
      prismicPage: homePage,
      prismicSlices,
      slug,
      topProducts,
      initialNavItems,
      listOfLinks,
    },
    revalidate: ISR_CONTROL.ONCE_PER_DAY,
  };
};

export default HomePage;
